<template>
  <div>
    <AmendmentForm />
  </div>
</template>

<script>
import AmendmentForm from './components/AmendmentForm.vue';

export default {
  name: 'App',
  components: {
    AmendmentForm,
  },
};
</script>


<head>
  <meta charset="UTF-8" />
  <title>Stemlijst</title>
  <link rel="stylesheet" href="./assets/style.css" />
</head>

<body></body>

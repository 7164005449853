<style src="@/assets/style.css"></style>

<template>
  <div>
    <img :src="logoUrl" class="logo-img" alt="Logo">
  </div>
  <div>
    <button @click="wipeData">Leegmaken</button>
  </div>
  <div>
    <h1>Amendement/Motie Form</h1>
    <form @submit.prevent="submitForm">
      <div>
  <label>Type:</label>
  <label>
    <input type="radio" v-model="type" value="proposal"> Voorstel
  </label>
  <label>
    <input type="radio" v-model="type" value="motie"> Motie
  </label>
  <label>
    <input type="radio" v-model="type" value="amendement"> Amendement
  </label>
</div>

      <div>
        <label>
          Naam:
          <input type="text" v-model="naam" required />
        </label>
      </div>
      <div>
        <label>
          Indiener:
          <input type="text" v-model="indiener" required />
        </label>
      </div>
      <div>
        <label>
          Mede:
          <input type="checkbox" v-model="mede" />
        </label>
      </div>
      <div>
        <label>
          <input type="radio" v-model="stem" value="voor" /> Voor
        </label>
        <label>
          <input type="radio" v-model="stem" value="tegen" /> Tegen
        </label>
        <label>
          <input type="radio" v-model="stem" value="afwachten-verhaal" /> Afwachten verhaal
        </label>
        <label>
          <input type="radio" v-model="stem" value="afwachten-gedeputeerde" /> Afwachten gedeputeerde
        </label>
      </div>
      <div>
          <label for="stemverklaring">Stemverklaring:</label><br>
          <textarea id="stemverklaring" v-model="stemverklaring"></textarea>
      </div>
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>
    <h2>Results</h2>
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Naam</th>
          <th>Indiener</th>
          <th>Mede</th>
          <th>Stem</th>
          <th>Stemverklaring</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(result, index) in results" :key="index" :class="result.stemClass">
          <td>{{ result.type }}</td>
          <td>{{ result.naam }}</td>
          <td>{{ result.indiener }}</td>
          <td>{{ result.mede }}</td>
          <td>{{ result.stem }}</td>
          <td>{{ result.stemverklaring }}</td>
        </tr>
      </tbody>
    </table>
    <button @click="generatePdf">Generate PDF</button>
  </div>
</template>

<script>
  import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      type: '',
      naam: '',
      indiener: '',
      mede: false,
      stem: '',
      results: [],
    };
  },
 computed: {
    logoUrl() {
      const domainName = window.location.hostname.split(".")[0];
      let logoUrl = "./" + domainName + ".png";
      try {
        require(`./${domainName}.png`);
      } catch (error) {
        logoUrl = "";
      }
      return logoUrl;
    },
  },

  methods: {
generatePdf() {
  const doc = new jsPDF();

  const rows = [];
  const columns = ['Type', 'Naam', 'Indiener', 'Mede', 'Stem', 'Stemverklaring'];

  this.results.forEach(result => {
    const row = [      result.type === 'amendement' ? 'A' : 'M',      result.naam,      result.indiener,      result.mede ? 'Ja' : 'Nee',      result.stem,      result.stemverklaring    ];
    rows.push(row);
  });

  doc.autoTable({
    head: [columns],
    body: rows,
    bodyStyles: {
      fillColor: [230, 230, 230],
    },
    columnStyles: {
      0: { cellWidth: 12 },
      1: { cellWidth: 40 },
      2: { cellWidth: 20 },
      3: { cellWidth: 15 },
      4: { cellWidth: 20 },
      5: { cellWidth: 75 },
    },
    styles: {
      overflow: 'linebreak',
    },
    startY: 40,
    margin: { top: 40 },
    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.index === 4) {
        var stemClass = data.row.raw[4] === 'voor'
          ? 'green'
          : data.row.raw[4] === 'tegen'
            ? 'red'
            : data.row.raw[4] === 'afwachten-verhaal' || data.row.raw[4] === 'afwachten-gedeputeerde'
              ? 'orange'
              : '';
        doc.setFillColor(stemClass);
        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');
      }
    },
  });

  doc.save('results.pdf');
},



    submitForm() {
      const stemClass =
        this.stem === 'voor'
          ? 'green'
          : this.stem === 'tegen'
          ? 'red'
          : this.stem === 'afwachten-verhaal' || this.stem === 'afwachten-gedeputeerde'
          ? 'orange'
          : '';

      this.results.push({
        type: this.type,
        naam: this.naam,
        indiener: this.indiener,
        mede: this.mede ? 'Ja' : 'Nee',
        stem: this.stem,
        stemverklaring: this.stemverklaring,
        stemClass: stemClass,
      });

      localStorage.setItem('results', JSON.stringify(this.results));

      this.type = '';
      this.naam = '';
      this.indiener = '';
      this.mede = false;
      this.stem = '';
      this.stemverklaring = '';
    },

    wipeData() {
      localStorage.clear();
      this.results = [];
    },
  },
};
       
</script>
